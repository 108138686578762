import { useEffect, useState } from 'react'

/**
 * Indicates whether a loading indicator should be shown for the given promise.
 *
 * A loading indicator should be shown if the given promise has not been
 * resolved almost instantly, within 500 milliseconds.
 *
 * @param promise - Promise to be resolved before the indicator is hidden.
 */
export function useLoadingIndicator(promise?: Promise<void>) {
  const [shouldShowLoadingIndicator, setShouldShowLoadingIndicator] = useState(false)

  useEffect(() => {
    if (promise) {
      const loadingIndicatorTimeout = setTimeout(() => {
        setShouldShowLoadingIndicator(true)
      }, 500)

      promise.finally(() => {
        clearTimeout(loadingIndicatorTimeout)
        setShouldShowLoadingIndicator(false)
      })

      return () => clearTimeout(loadingIndicatorTimeout)
    }
  }, [promise])

  return shouldShowLoadingIndicator
}
