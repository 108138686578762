import { useEffect, useState } from 'react'

export function useImage(src: string, setLoadingPromise?: (promise: Promise<void>) => void) {
  const [image, setImage] = useState<HTMLImageElement | null>(null)

  useEffect(() => {
    const loadingPromise = new Promise<void>((resolve) => {
      const image = new Image()
      image.src = src
      image.onload = () => {
        resolve()
        setImage(image)
      }
    })
    if (setLoadingPromise) {
      setLoadingPromise(loadingPromise)
    }
  }, [src, setLoadingPromise])

  return image
}
