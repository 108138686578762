import type { AxiosError } from 'axios'

import { mimeTypeMap } from '../templateComponents/Workspace/daliConfig'

export async function getFileFromCanvas(canvas: HTMLCanvasElement, filename: string) {
  const mimeType = mimeTypeMap[filename.toLowerCase().split('.').pop()]
  const blob = await new Promise<Blob | null>((resolve) => {
    canvas.toBlob((blob) => resolve(blob), mimeType)
  })
  return new File([blob!], filename, { type: mimeType })
}

export function getFilename(src: string) {
  const url = new URL(src, window.location.origin)
  let filename = url.searchParams.has('remote')
    ? url.searchParams.get('remote')!.split('/').pop()!
    : decodeURIComponent(url.pathname.split('/').pop()!)
  try {
    // Decode double encoded source
    filename = decodeURIComponent(filename)
  } catch {}
  return filename
}

export function getRelativeDelta(delta: number, baseSize: number, baseClientSize: number) {
  const base = (1 / baseSize) * baseClientSize
  return delta / base
}

export function getNormalizedZoomFactor(zoomFactor: number) {
  // Round to one decimal place to avoid floating point errors
  return parseFloat(zoomFactor.toFixed(1))
}

export function getErrorReasonKey(exception: unknown): 'fileSizeTooLarge' | 'unsupportedMediaType' | 'unknown' {
  const { response } = exception as AxiosError
  switch (response?.status) {
    case 413:
      return 'fileSizeTooLarge'
    case 415:
      return 'unsupportedMediaType'
    default:
      return 'unknown'
  }
}
