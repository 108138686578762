import type { ComponentProps } from 'react'
import { useEffect, useState } from 'react'

import { EditorSpotlight } from '../EditorSpotlight'
import { ImageCanvas } from './ImageCanvas'
import { ImageEditorMenu } from './ImageEditorMenu'
import { ImageMoveControls } from './ImageMoveControls'
import { getErrorReasonKey, getFileFromCanvas, getFilename } from './ImageEditor.helpers'
import { useImage } from './useImage'
import { useImageOffset } from './useImageOffset'
import { useLoadingIndicator } from './useLoadingIndicator'
import { useWheelZoom } from './useWheelZoom'
import checkSupportedImageMediaType from '../../utils/checkSupportedImageMediaType'
import compose from '../../utils/compose'
import translate from '../../utils/translate'
import useHelpCenterLink from '../../utils/hooks/useHelpCenterLink'
import withI18n from '../withI18n'

type ImageEditorProps = Readonly<{
  src: string
  previewSrc?: string
  aspectRatioMap: AspectRatioMap
  edit: EditState
  onInit: (imageFile: File) => Promise<void>
  onChange: (edit: EditState, imageFile: File, newImageSourceFile?: File) => Promise<void>
  onCancel: () => void
  t: TranslateProps['t']
}>

export type AspectRatioLabel = '1:1' | '2:3' | '3:1' | '3:2' | '4:3' | '5:1' | '16:9' | 'circle' | 'original'
type AspectRatioMap = Partial<Record<'1:1' | '2:3' | '3:1' | '3:2' | '4:3' | '5:1' | '16:9' | 'circle', number>>
type ErrorMessage = `${'couldNotUseImage' | 'couldNotSaveImage'}:${ReturnType<typeof getErrorReasonKey>}` | null

type EditState = Readonly<{
  aspectRatio: { label: AspectRatioLabel; value?: number }
  offset: readonly [number, number]
  zoom: number
}>

/**
 * Image editor component that is used in image content elements, allowing
 * users to control the portion of the image that should be displayed, as well
 * as the aspect ratio and zoom level.
 *
 * @param src The source image to be edited
 * @param previewSrc Optional preview image to be displayed while the source image is loading
 * @param aspectRatioMap The aspect ratio options to be displayed in the editor
 * @param edit The edit state (aspect ratio, offset, zoom factor)
 * @param onInit Callback to handle the initial render of the image, e.g. initial auto-edit
 * @param onChange Callback to handle changes to the image, i.e. save
 * @param onCancel Callback to handle canceling the editing
 */
export const ImageEditor = compose(
  withI18n('interface'),
  translate('components.imageEditorComponent'),
)(ImageEditorRaw) as (props: Omit<ComponentProps<typeof ImageEditorRaw>, 't'>) => JSX.Element

export function ImageEditorRaw({
  src,
  previewSrc,
  aspectRatioMap,
  edit,
  onInit,
  onChange,
  onCancel,
  t,
}: ImageEditorProps) {
  const [canvas, setCanvas] = useState<HTMLCanvasElement | null>(null)
  const [canvasContainer, setCanvasContainer] = useState<HTMLDivElement | null>(null)
  const [isCanvasReady, setIsCanvasReady] = useState(false)
  const [isSaving, setIsSaving] = useState(false)

  // This can be replaced with `useTransition` in React 19
  const [isPending, setIsPending] = useState(false)
  async function pending(callbackFn: () => Promise<void>) {
    setIsPending(true)
    try {
      await callbackFn()
    } finally {
      setIsPending(false)
    }
  }

  const isEditingEnabled = isCanvasReady && !isPending
  const helpCenterUrl = useHelpCenterLink('IMAGE_FORMATS')

  // Image attributes
  const [imageSrc, setImageSrc] = useState(src)
  const [filename, setFilename] = useState(getFilename(src))

  // Image state
  const [zoomFactor, setZoomFactor] = useState(edit.zoom)
  const [aspectRatio, setAspectRatio] = useState(edit.aspectRatio)
  const [maxImageOffset, setMaxImageOffset] = useState<readonly [number, number]>([0, 0])
  const [imageOffset, setImageOffset, controls, canDrag, isDragging] = useImageOffset(
    canvas,
    edit.offset,
    maxImageOffset,
    isEditingEnabled,
  )
  useWheelZoom(canvas, setZoomFactor, isEditingEnabled)

  // Loading spinner
  const [loadingPromise, setLoadingPromise] = useState<Promise<void>>()
  const showLoadingSpinner = useLoadingIndicator(loadingPromise)

  // Source image (loaded on component mount and when the image source changes)
  const sourceImage = useImage(imageSrc, setLoadingPromise)

  // Source file (only set when a new file is chosen)
  const [sourceFile, setSourceFile] = useState<File>()

  // Error state (reset whenever a change is made)
  const [errorMessage, setErrorMessage] = useState<ErrorMessage>(null)
  const [errorKey, errorReason] = errorMessage?.split(':') ?? []
  useEffect(() => {
    setErrorMessage(null)
  }, [aspectRatio, imageOffset, zoomFactor])

  async function handleFileChange(file: File) {
    await pending(async () => {
      const isSupportedImageType = await checkSupportedImageMediaType(file)

      if (!isSupportedImageType) {
        setErrorMessage('couldNotUseImage:unsupportedMediaType')
        return
      }

      // The file should not exceed 20 MB, which is the maximum file size limit
      // of the ePages storage service.
      if (file.size > 2e7) {
        setErrorMessage('couldNotUseImage:fileSizeTooLarge')
        return
      }

      setSourceFile(file)

      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        setImageSrc(reader.result as string)
        setFilename(file.name)
        setZoomFactor(1)
        setImageOffset([0, 0])

        // If the current aspect ratio option is set to "original"
        // and a new image is uploaded, update the aspect ratio value
        // to the new image's original aspect ratio.
        if (aspectRatio.label === 'original') {
          const image = new Image()
          image.src = reader.result as string
          image.onload = () => {
            setAspectRatio({
              label: 'original',
              value: image.width / image.height,
            })
          }
        }
      }
    })
  }

  function handleChange() {
    setLoadingPromise(
      pending(async () => {
        if (canvas) {
          try {
            const imageFile = await getFileFromCanvas(canvas, filename)
            const currentEdit: EditState = { aspectRatio, offset: imageOffset, zoom: zoomFactor }
            setIsSaving(true)
            await onChange(currentEdit, imageFile, sourceFile)
          } catch (exception) {
            setErrorMessage(`couldNotSaveImage:${getErrorReasonKey(exception)}`)
          } finally {
            setIsSaving(false)
          }
        }
      }),
    )
  }

  function handleInit(canvas: HTMLCanvasElement) {
    setLoadingPromise(
      pending(async () => {
        try {
          setIsSaving(true)
          await onInit(await getFileFromCanvas(canvas, filename))
        } catch (exception) {
          setErrorMessage(`couldNotSaveImage:${getErrorReasonKey(exception)}`)
        } finally {
          setIsSaving(false)
        }
      }),
    )
  }

  return (
    <EditorSpotlight onCancel={onCancel} title={t('spotlightDialog.accessibilityLabel')}>
      <span role="status" className="visually-hidden">
        {t(`editorStatus.${isEditingEnabled ? 'ready' : isSaving ? 'saving' : 'loading'}.accessibilityLabel`)}
      </span>
      <div
        className="ep-image-editor"
        aria-busy={!isEditingEnabled}
        data-can-drag={canDrag || null}
        data-is-dragging={isDragging || null}
        data-is-saving={isSaving || null}
      >
        {errorKey && errorReason && (
          <div className="ep-notification-danger" role="alert">
            {t(`errorMessages.${errorKey}`, {
              reason: t(`errorMessages.reasons.${errorReason}`, { maxMbFileSize: 20 }),
            })}
            {errorReason === 'unsupportedMediaType' && (
              <a href={helpCenterUrl} target="_blank" className="ep-form-row-text-external-link" rel="noreferrer">
                {t('errorMessages.helpCenterLink')}
              </a>
            )}
          </div>
        )}
        <ImageEditorMenu
          t={t}
          referenceElement={canvasContainer}
          disabled={!isEditingEnabled}
          aspectRatio={aspectRatio}
          aspectRatioOptions={{
            ...aspectRatioMap,
            original: sourceImage ? sourceImage.width / sourceImage.height : undefined,
          }}
          zoomFactor={zoomFactor}
          onZoomFactorChange={setZoomFactor}
          onAspectRatioChange={setAspectRatio}
          onFileChange={handleFileChange}
          onCancel={onCancel}
          onSave={handleChange}
        />
        <ImageMoveControls controls={controls} t={t} />
        <div
          ref={setCanvasContainer}
          style={{
            aspectRatio: aspectRatio.value ?? undefined,
            clipPath: aspectRatio.label === 'circle' ? 'circle(50% at center)' : '',
          }}
        >
          {sourceImage && (
            <ImageCanvas
              image={sourceImage}
              aspectRatio={aspectRatio.value}
              zoomFactor={zoomFactor}
              offset={imageOffset}
              onUpdate={(canvas, offset, maxOffset) => {
                // ImageCanvas returns the offset that was used to draw the image. It
                // is based on the offset that was passed in but might differ to keep
                // the image within the bounds of the canvas. Update the offset state
                // with the used offset if it differs.
                if (imageOffset[0] !== offset[0] || imageOffset[1] !== offset[1]) {
                  setImageOffset(offset)
                }

                // For button state management
                if (maxImageOffset[0] !== maxOffset[0] || maxImageOffset[1] !== maxOffset[1]) {
                  setMaxImageOffset(maxOffset)
                }

                // Default to the aspect ratio of the source image
                if (!aspectRatio.value) {
                  setAspectRatio({ label: 'original', value: sourceImage.width / sourceImage.height })
                }

                if (!isCanvasReady) {
                  setIsCanvasReady(true)
                  setCanvas(canvas)
                  handleInit(canvas)
                }
              }}
            />
          )}
          {showLoadingSpinner && <span className="ep-image-editor-loading-spinner" />}
          {!sourceImage && previewSrc && (
            <img className="ep-image-editor-loading-preview-image" src={previewSrc} alt="" />
          )}
        </div>
      </div>
    </EditorSpotlight>
  )
}
